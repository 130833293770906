/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <PTabView :activeIndex.sync="activeTab" class="mt-5">
          <PTabPanel header="Mes placements">
            <PlacementList />
          </PTabPanel>
          <!-- <PTabPanel header="Point de présence">
            <PointPresenceList />
          </PTabPanel>
          <PTabPanel header="Cessation de service de présence">
            <PointPresenceList />
          </PTabPanel>
          <PTabPanel header="Liste des employés">
            <PlacesList />
          </PTabPanel> -->
        </PTabView>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";
import PointPresenceList from "./PointPresenceList.vue";
import PlacesList from "./PlacesList.vue";
import PlacementList from "../../common/PlacementList.vue";
export default {
  props: {},
  components: {
    PointPresenceList,
    PlacesList,
    PlacementList,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      activeTab: 0,
    };
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({}),
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
